import React from "react";
import { IconProps } from "../icon";

export const Glasses_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M10 11.535a4.008 4.008 0 014 0M8.828 9.172a4 4 0 11-5.657 5.656 4 4 0 015.657-5.656zm12 0a4 4 0 11-5.656 5.656 4 4 0 015.656-5.656z"
    />
  </svg>
);
