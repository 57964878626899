import React from "react";
import { IconProps } from "../icon";

export const Dotpoints_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 5H10m11 14H10m11-7H10M6 5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm0 14a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm0-7a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
    />
  </svg>
);
