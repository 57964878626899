import React from "react";
import { IconProps } from "../icon";

export const SearchWholeWordIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M1.5 3h21v1.5h-21V3zM21 6h-1.5v12H21V6zm-4.092 6.58a3.53 3.53 0 00-.54-1.178 2.619 2.619 0 00-.913-.795c-.367-.194-.802-.29-1.3-.29-.296 0-.57.036-.82.11a2.639 2.639 0 00-.68.307 2.586 2.586 0 00-.547.477l-.269.387v-4.73H10.5V18h1.34v-.862l.188.262c.131.153.284.285.457.404.175.117.373.21.597.279.223.069.47.102.746.102.53 0 1-.107 1.406-.318a2.976 2.976 0 001.023-.88c.274-.374.481-.814.621-1.318.14-.507.21-1.054.21-1.645a5.633 5.633 0 00-.18-1.443zm-2.219-.91c.227.107.424.264.585.471.164.21.292.47.383.776.076.26.123.556.133.88l-.01.188c0 .49-.05.93-.15 1.303-.1.37-.242.68-.417.921-.175.243-.39.428-.631.55-.484.242-1.14.248-1.604.022a1.897 1.897 0 01-.59-.444 1.91 1.91 0 01-.326-.55s-.269-.671-.269-1.421.269-1.503.269-1.503c.093-.266.204-.477.336-.645a1.93 1.93 0 01.636-.518c.252-.129.547-.193.88-.193.285 0 .546.055.776.163zM22.5 19.5h-21V21h21v-1.5zM4.22 15l-1.092 3.047H1.5l.038-.108 3.661-10.63h1.412l3.727 10.738H8.715L7.548 15H4.219zM5.9 9.633h-.033l-1.357 4.13h2.761l-1.371-4.13z"
      clipRule="evenodd"
    />
  </svg>
);
