import React from "react";
import { IconProps } from "../icon";

export const Cursor_03Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4.712 3.674c-.541-.212-.811-.319-.984-.262a.5.5 0 00-.316.317c-.057.172.049.442.262.983l5.3 13.456c.172.435.258.652.398.74a.5.5 0 00.408.055c.158-.047.298-.234.579-.609l2.14-2.854 3.45 4.743c.19.26.284.391.405.446a.5.5 0 00.338.026c.128-.035.242-.149.47-.377l3.176-3.176c.227-.228.341-.342.377-.47a.5.5 0 00-.027-.337c-.054-.121-.185-.216-.446-.406L15.5 12.5l2.854-2.14c.374-.281.561-.422.608-.58a.5.5 0 00-.055-.408c-.087-.14-.305-.225-.74-.397l-13.455-5.3z"
    />
  </svg>
);
