import React from "react";
import { IconProps } from "../icon";

export const Bold_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 4v16M9.5 4h6a4 4 0 010 8h-6 7a4 4 0 010 8h-7m0-16v16m0-16H4m5.5 16H4"
    />
  </svg>
);
