import React from "react";
import { IconProps } from "../icon";

export const FigmaIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 1.5H8.5a3.5 3.5 0 100 7m3.5-7v7m0-7h3.5a3.5 3.5 0 110 7m-3.5 0H8.5m3.5 0v7m0-7h3.5m-7 0a3.5 3.5 0 100 7m3.5 0H8.5m3.5 0V19a3.5 3.5 0 11-3.5-3.5m7-7a3.5 3.5 0 110 7 3.5 3.5 0 010-7z"
    />
  </svg>
);
