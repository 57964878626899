import React from "react";
import { IconProps } from "../icon";

export const Glasses_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M10 14.535a4.008 4.008 0 014 0M2 15l.701-7.015c.027-.266.04-.399.06-.513A3 3 0 015.485 5.01C5.599 5 5.733 5 6 5m16 10l-.701-7.015c-.027-.266-.04-.399-.06-.513a3 3 0 00-2.723-2.463C18.402 5 18.268 5 18 5m-9.172 7.172a4 4 0 11-5.656 5.656 4 4 0 015.656-5.656zm12 0a4 4 0 11-5.656 5.656 4 4 0 015.656-5.656z"
    />
  </svg>
);
