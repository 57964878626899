import React from "react";
import { IconProps } from "../icon";

export const PenTool_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M18 13l-1.3-6.498c-.072-.363-.108-.545-.197-.692a1 1 0 00-.312-.325c-.144-.094-.324-.138-.684-.225L2 2m0 0l3.26 13.507c.087.36.13.54.225.684a1 1 0 00.325.312c.147.088.329.125.692.197L13 18M2 2l7.586 7.586m6.545 11.283l4.738-4.738c.396-.396.594-.594.668-.822a1 1 0 000-.618c-.074-.228-.272-.426-.668-.822l-.738-.738c-.396-.396-.594-.594-.822-.668a1 1 0 00-.618 0c-.228.074-.426.272-.822.668L13.13 17.87c-.396.396-.594.594-.668.822a1 1 0 000 .618c.074.228.272.426.668.822l.738.738c.396.396.594.594.822.668a1 1 0 00.618 0c.228-.074.426-.272.822-.668zM13 11a2 2 0 11-4 0 2 2 0 014 0z"
    />
  </svg>
);
