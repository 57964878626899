import React from "react";
import { IconProps } from "../icon";

export const SearchReplaceIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M4.832 5.609l3.391 3.403 3.327-3.336-1.05-1.05-1.518 1.51-.012-2.4a.784.784 0 01.75-.789H12V1.5H9.72A2.223 2.223 0 007.5 3.733V6.15l-1.61-1.6-1.058 1.059zm10.003 2.69h.017c.273.467.676.701 1.209.701.588 0 1.059-.251 1.41-.754.353-.503.529-1.17.529-2 0-.766-.15-1.37-.452-1.811-.301-.441-.731-.662-1.289-.662-.608 0-1.077.29-1.407.871h-.017V1.5H13.5v7.378h1.335V8.3zm-.02-1.591v-.511c0-.37.086-.67.261-.9.178-.231.4-.346.668-.346.284 0 .503.116.655.35.154.23.232.552.232.964 0 .496-.084.88-.253 1.153-.167.27-.402.404-.706.404a.743.743 0 01-.617-.316c-.16-.211-.24-.477-.24-.798zM13.5 19.154c-.383.23-.937.346-1.661.346-.846 0-1.53-.266-2.054-.799C9.262 18.17 9 17.481 9 16.64c0-.972.28-1.738.839-2.296.562-.563 1.313-.844 2.252-.844.65 0 1.119.09 1.409.268v1.497a1.89 1.89 0 00-1.188-.413c-.488 0-.875.149-1.162.447-.283.294-.424.702-.424 1.223 0 .507.136.905.408 1.196.273.287.647.43 1.124.43.423 0 .837-.138 1.242-.413v1.419zM6 10.5L4.5 12v9L6 22.5h10.5L18 21v-9l-1.5-1.5H6zM6 12h10.5v9H6v-9z"
      clipRule="evenodd"
    />
  </svg>
);
