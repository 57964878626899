import React from "react";
import { IconProps } from "../icon";

export const Beaker_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 6v4.501c0 .551 0 .827-.069 1.082a2 2 0 01-.295.631c-.153.216-.364.393-.787.745L4.15 16.041c-.423.352-.634.529-.787.745a2 2 0 00-.295.631C3 17.672 3 17.947 3 18.5v.301c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874C4.52 22 5.08 22 6.2 22h11.6c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C21 20.48 21 19.92 21 18.8v-.301c0-.551 0-.827-.069-1.082-.06-.226-.16-.44-.295-.631-.153-.216-.364-.393-.787-.745l-3.698-3.082c-.423-.352-.634-.528-.787-.745a1.999 1.999 0 01-.295-.631C15 11.328 15 11.053 15 10.5V6M8.3 6h7.4c.28 0 .42 0 .527-.054a.5.5 0 00.218-.219c.055-.107.055-.247.055-.527V2.8c0-.28 0-.42-.055-.527a.5.5 0 00-.218-.219C16.12 2 15.98 2 15.7 2H8.3c-.28 0-.42 0-.527.054a.5.5 0 00-.218.219C7.5 2.38 7.5 2.52 7.5 2.8v2.4c0 .28 0 .42.054.527a.5.5 0 00.219.218C7.88 6 8.02 6 8.3 6zM5.5 17h13c.465 0 .697 0 .89.038a2 2 0 011.572 1.572c.038.193.038.425.038.89s0 .697-.038.89a2 2 0 01-1.572 1.572c-.193.038-.425.038-.89.038h-13c-.465 0-.697 0-.89-.038a2 2 0 01-1.572-1.572C3 20.197 3 19.965 3 19.5s0-.697.038-.89a2 2 0 011.572-1.572C4.803 17 5.035 17 5.5 17z"
    />
  </svg>
);
