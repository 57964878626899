import React from "react";
import { IconProps } from "../icon";

export const Globestand_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2.661 18.34l4.594-4.595M18.218 2.783A9.5 9.5 0 114.783 16.218M17 22H7m5 0v-3m5.5-9.5a6 6 0 11-12 0 6 6 0 0112 0z"
    />
  </svg>
);
