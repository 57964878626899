import React from "react";
import { IconProps } from "../icon";

export const SearchRegexIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M15.018 3h1.464v4.67l3.841-2.336.728 1.327L17.205 9l3.846 2.339-.728 1.327-3.841-2.336V15h-1.464v-4.67l-3.841 2.336-.728-1.327L14.295 9l-3.846-2.339.728-1.327 3.841 2.336V3zM3 15h6v6H3v-6z"
      clipRule="evenodd"
    />
  </svg>
);
