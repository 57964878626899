import React from "react";
import { IconProps } from "../icon";

export const CursorClick_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 3.5V2M5.06 5.06L4 4m1.06 9L4 14.06m9-9L14.06 4M3.5 9H2m6.5-.5l4.111 12.778 2.889-2.89L19.111 22 22 19.111 18.389 15.5l2.889-2.889L8.5 8.5z"
    />
  </svg>
);
