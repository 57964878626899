import React from "react";
import { IconProps } from "../icon";

export const CompassIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 4a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm0 0V2m9 12.938A11.971 11.971 0 0112 19a11.971 11.971 0 01-9-4.063m7.745-6.275L3 22M13.255 8.662L21 22"
    />
  </svg>
);
