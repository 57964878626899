import React from "react";
import { IconProps } from "../icon";

export const TelescopeIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M13.122 14.845L18 22m-7.121-7.155L6 22m8-8.8a2 2 0 11-4 0 2 2 0 014 0zm3.149-8.668L5.365 7.69c-.27.072-.406.109-.495.189a.5.5 0 00-.155.267c-.025.118.011.253.084.524l.88 3.284c.072.27.109.405.189.495a.5.5 0 00.268.154c.117.025.252-.011.523-.083l11.784-3.158-1.294-4.83zM21.793 9.5c-1.082.29-1.623.434-2.093.335a2 2 0 01-1.07-.618c-.322-.357-.466-.898-.756-1.98l-.156-.58c-.29-1.082-.434-1.623-.335-2.092a2 2 0 01.618-1.07c.357-.322.898-.467 1.98-.757.27-.072.406-.109.523-.084a.5.5 0 01.268.155c.08.09.116.224.189.495l1.398 5.216c.072.27.108.406.083.523a.5.5 0 01-.154.268c-.09.08-.225.116-.495.189zm-18.29 2.83l1.351-.362c.27-.073.406-.11.495-.19a.5.5 0 00.155-.267c.025-.117-.011-.253-.084-.523l-.362-1.352c-.073-.27-.109-.406-.19-.495a.5.5 0 00-.267-.155c-.117-.025-.253.011-.523.084l-1.352.362c-.27.073-.406.109-.495.19a.5.5 0 00-.155.267c-.025.117.011.253.084.523l.362 1.352c.073.27.109.406.19.495a.5.5 0 00.267.155c.117.025.253-.011.523-.084z"
    />
  </svg>
);
