import React from "react";
import { IconProps } from "../icon";

export const DropperIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M10.5 6.5l7 7M2 22s4.5-.5 7-3L21 7a2.828 2.828 0 10-4-4L5 15c-2.5 2.5-3 7-3 7z"
    />
  </svg>
);
