import React from "react";
import { IconProps } from "../icon";

export const CommandIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 9V6a3 3 0 10-3 3h3zm0 0v6m0-6h6m-6 6v3a3 3 0 11-3-3h3zm0 0h6m0 0h3a3 3 0 11-3 3v-3zm0 0V9m0 0V6a3 3 0 113 3h-3z"
    />
  </svg>
);
