import React from "react";
import { IconProps } from "../icon";

export const TypeStrikethrough_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 7V6c0-.541.215-1.032.564-1.392M9 20h6m-3-8v8M3 3l18 18M9.5 4H17c.932 0 1.398 0 1.765.152a2 2 0 011.083 1.083C20 5.602 20 6.068 20 7m-8-3v2.5"
    />
  </svg>
);
