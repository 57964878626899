import React from "react";
import { IconProps } from "../icon";

export const SearchCaseSensitiveIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M11.242 13.578l1.337 3.525h1.636L9.355 4.5h-1.53L3 17.103h1.643l1.256-3.525h5.343zM8.716 6.68l.067.202 1.976 5.361H6.382l1.961-5.361.066-.202.057-.234.048-.228.032-.19h.034l.036.19.043.228.057.234zm11.976 9.016V17.1h1.44v-5.853c0-1.079-.27-1.908-.808-2.492-.539-.583-1.334-.874-2.382-.874-.299 0-.602.028-.909.084-.308.055-.6.126-.875.211a6.35 6.35 0 00-.742.278c-.22.1-.392.193-.514.282v1.476a4.518 4.518 0 011.356-.81 4.39 4.39 0 011.578-.29c.282 0 .537.042.769.128.232.085.43.219.594.4.163.182.29.419.378.708.088.29.132.64.132 1.05l-2.716.378c-.516.07-.96.19-1.332.356-.373.166-.68.376-.923.628a2.399 2.399 0 00-.54.87 3.2 3.2 0 00-.175 1.082c0 .369.063.712.186 1.032.123.32.304.595.544.826.24.231.54.414.897.55.357.134.77.202 1.239.202.603 0 1.14-.138 1.613-.417.472-.28.858-.681 1.156-1.21h.034zM17.5 13.08a4.806 4.806 0 011.012-.223l2.197-.308v.914c0 .369-.061.712-.184 1.032a2.59 2.59 0 01-.515.835 2.36 2.36 0 01-.786.558 2.446 2.446 0 01-1.002.203 2.2 2.2 0 01-.742-.114 1.546 1.546 0 01-.536-.317 1.344 1.344 0 01-.33-.474 1.507 1.507 0 01-.114-.59 2.4 2.4 0 01.082-.66c.056-.193.158-.36.303-.5.146-.14.351-.26.615-.356z"
      clipRule="evenodd"
    />
  </svg>
);
