import React from "react";
import { IconProps } from "../icon";

export const Trophy_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 17a6.5 6.5 0 01-6.5-6.5V4.556c0-.519 0-.778.094-.979a1 1 0 01.483-.483C6.278 3 6.537 3 7.056 3h9.888c.519 0 .778 0 .979.094a1 1 0 01.483.483c.094.201.094.46.094.979V10.5A6.5 6.5 0 0112 17zm0 0v4m5 0H7M22 5v5M2 5v5"
    />
  </svg>
);
