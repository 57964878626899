import React from "react";
import { IconProps } from "../icon";

export const Scissors_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20 4L8.5 15.5m0-7L20 20M6 3a3 3 0 110 6 3 3 0 010-6zm0 12a3 3 0 110 6 3 3 0 010-6z"
    />
  </svg>
);
