import React from "react";
import { IconProps } from "../icon";

export const LetterSpacing_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 13h6m-8 4l4.272-9.398c.231-.509.347-.763.507-.842a.5.5 0 01.442 0c.16.079.276.333.507.842L17 17m4-14v18M3 3v18"
    />
  </svg>
);
