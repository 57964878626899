import React from "react";
import { IconProps } from "../icon";

export const BookOpen_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 20H5.2c-1.12 0-1.68 0-2.108-.218a2 2 0 01-.874-.874C2 18.48 2 17.92 2 16.8V7.2c0-1.12 0-1.68.218-2.108a2 2 0 01.874-.874C3.52 4 4.08 4 5.2 4h.4c2.24 0 3.36 0 4.216.436a4 4 0 011.748 1.748C12 7.04 12 8.16 12 10.4m0 9.6v-9.6m0 9.6h6.8c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C22 18.48 22 17.92 22 16.8V7.2c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874C20.48 4 19.92 4 18.8 4h-.4c-2.24 0-3.36 0-4.216.436a4 4 0 00-1.748 1.748C12 7.04 12 8.16 12 10.4"
    />
  </svg>
);
