import React from "react";
import { IconProps } from "../icon";

export const Beaker_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M10 2v4.66c0 .218 0 .326-.033.413a.47.47 0 01-.138.198c-.07.06-.183.102-.409.185a7.5 7.5 0 105.16 0c-.226-.083-.339-.125-.409-.185a.469.469 0 01-.138-.198C14 6.986 14 6.878 14 6.66V2M8.5 2h7"
    />
  </svg>
);
