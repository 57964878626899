import React from "react";
import { IconProps } from "../icon";

export const ColorsIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 20.472a6 6 0 105.58-10.262m-11.16 0a6 6 0 107.16 3.58M18 8A6 6 0 116 8a6 6 0 0112 0z"
    />
  </svg>
);
