import React from "react";
import { IconProps } from "../icon";

export const SearchReplaceAllIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M17.401 4.015c.22-.464.533-.697.938-.697.372 0 .659.177.86.53.2.353.301.836.301 1.448 0 .665-.117 1.198-.352 1.6-.235.403-.549.604-.941.604-.355 0-.623-.187-.806-.56h-.01v.463H16.5V1.5h.89v2.515h.011zm-.025 1.651c0 .257.054.47.16.639.108.168.245.253.412.253.203 0 .36-.108.47-.323.113-.218.17-.526.17-.923 0-.33-.052-.587-.156-.771-.101-.187-.247-.28-.436-.28-.179 0-.327.092-.445.276-.117.184-.175.424-.175.72v.41zM6.18 11.543L3 8.352l.993-.993 1.509 1.5V6.594A2.084 2.084 0 017.583 4.5h3.516v1.357H7.583a.735.735 0 00-.703.74l.011 2.25L8.314 7.43l.985.984-3.12 3.128zm7.853-4.148H15V4.831C15 3.611 14.528 3 13.584 3c-.202 0-.428.036-.676.109-.245.073-.44.156-.582.251v.998c.356-.305.731-.457 1.125-.457.393 0 .589.234.589.704l-.899.155C12.381 4.888 12 5.369 12 6.2c0 .394.092.71.275.947.185.235.438.352.76.352.435 0 .764-.24.986-.72h.012v.615zm.007-2.032v.29c0 .262-.06.48-.181.653a.551.551 0 01-.469.255.413.413 0 01-.33-.142.571.571 0 01-.12-.373c0-.332.167-.526.499-.582l.6-.1zM10.5 19.395h-.967v-.616H9.52c-.222.48-.55.721-.986.721-.32 0-.574-.117-.76-.352-.182-.237-.274-.553-.274-.947 0-.832.38-1.313 1.141-1.441l.899-.155c0-.47-.196-.704-.588-.704-.395 0-.77.152-1.125.457v-.998a2.17 2.17 0 01.581-.251c.248-.073.473-.109.676-.109.944 0 1.416.61 1.416 1.831v2.564zm-.96-1.743v-.289l-.601.1c-.332.057-.498.25-.498.583 0 .15.04.275.12.373a.413.413 0 00.33.142.551.551 0 00.468-.255c.12-.173.18-.391.18-.654zm4.353 1.848c.482 0 .851-.087 1.107-.26v-1.064c-.27.207-.546.31-.828.31-.318 0-.568-.108-.75-.323-.18-.218-.272-.516-.272-.896 0-.391.095-.697.284-.918.19-.223.449-.335.774-.335.291 0 .555.103.792.31v-1.123c-.194-.134-.507-.201-.94-.201-.625 0-1.126.21-1.5.633-.374.419-.56.993-.56 1.722 0 .631.174 1.146.523 1.546.35.4.806.599 1.37.599zM3 13.5L4.5 12H18l1.5 1.5V21L18 22.5H4.5L3 21v-7.5zm1.5 0V21H18v-7.5H4.5zm4.5-3L10.5 9H21l1.5 1.5V18L21 19.5v-9H9z"
      clipRule="evenodd"
    />
  </svg>
);
