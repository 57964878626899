import React from "react";
import { IconProps } from "../icon";

export const RightIndent_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 4H3m18 16H3m9-10.75H3m9 5.5H3m16.72-6.29l-3.867 2.9c-.29.217-.434.326-.486.459a.5.5 0 000 .362c.052.133.197.242.486.459l3.867 2.9c.412.309.618.463.79.46a.5.5 0 00.384-.192c.106-.136.106-.393.106-.908V9.1c0-.515 0-.772-.106-.908A.5.5 0 0020.51 8c-.172-.003-.378.151-.79.46z"
    />
  </svg>
);
