import React from "react";
import { IconProps } from "../icon";

export const Award_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7.967 14.722L7 22l4.588-2.753c.15-.09.225-.135.305-.152a.5.5 0 01.214 0c.08.017.155.062.305.152L17 22l-.966-7.279M19 9A7 7 0 115 9a7 7 0 0114 0z"
    />
  </svg>
);
