import React from "react";
import { IconProps } from "../icon";

export const Award_04Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8.876 13.095L4.701 7.877c-.26-.325-.39-.488-.482-.669a2 2 0 01-.178-.507C4 6.5 4 6.294 4 5.878V5.2c0-1.12 0-1.68.218-2.108a2 2 0 01.874-.874C5.52 2 6.08 2 7.2 2h9.6c1.12 0 1.68 0 2.108.218a2 2 0 01.874.874C20 3.52 20 4.08 20 5.2v.678c0 .416 0 .624-.04.823a2.002 2.002 0 01-.179.507c-.092.181-.222.344-.482.669l-4.175 5.218M5 3l7 9 7-9m-3.464 10.464a5 5 0 11-7.071 7.071 5 5 0 017.07-7.07z"
    />
  </svg>
);
