import React from "react";
import { IconProps } from "../icon";

export const Certificate_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6.5 20H5a2 2 0 01-2-2V4a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2h-1.5M12 19a3 3 0 100-6 3 3 0 000 6zm0 0h.021L8.83 22.193 6 19.364l3.02-3.02M12 19l3.193 3.193 2.828-2.829-3.02-3.02M9 6h6M7 9.5h10"
    />
  </svg>
);
