import React from "react";
import { IconProps } from "../icon";

export const Type_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 7c0-.932 0-1.398.152-1.765a2 2 0 011.083-1.083C5.602 4 6.068 4 7 4h10c.932 0 1.398 0 1.765.152a2 2 0 011.083 1.083C20 5.602 20 6.068 20 7M8 20h8M10.25 4v16m3.5-16v16"
    />
  </svg>
);
