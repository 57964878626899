import React from "react";
import { IconProps } from "../icon";

export const Pilcrow_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17.5 4v16m2-16H9a4 4 0 100 8h5m0-8v16m-2 0h7.5"
    />
  </svg>
);
