import React from "react";
import { IconProps } from "../icon";

export const Underline_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 4v6a7 7 0 11-14 0V4m3.5 0v6a7.003 7.003 0 005.14 6.75M4 21h16M3 4h7.5M17 4h4"
    />
  </svg>
);
