import React from "react";
import { IconProps } from "../icon";

export const LetterSpacing_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2 18h20M2 18l3-3m-3 3l3 3m17-3l-3-3m3 3l-3 3M7 3h10m-5 0v11"
    />
  </svg>
);
