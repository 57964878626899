import React from "react";
import { IconProps } from "../icon";

export const StandIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 17l-5 5m11-5l5 5M12 2v2m0 18v-5m-6.8 0h13.6c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C22 15.48 22 14.92 22 13.8V7.2c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874C20.48 4 19.92 4 18.8 4H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874C2 5.52 2 6.08 2 7.2v6.6c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874C3.52 17 4.08 17 5.2 17z"
    />
  </svg>
);
