import React from "react";
import { IconProps } from "../icon";

export const PenTool_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M13 6L6.502 7.3c-.363.072-.545.108-.692.197a1 1 0 00-.325.312c-.094.144-.138.324-.225.684L2 22m0 0l13.507-3.26c.36-.087.54-.13.684-.225a1 1 0 00.312-.325c.088-.148.125-.329.197-.692L18 11M2 22l7.586-7.586m11.283-6.546L16.13 3.131c-.396-.396-.594-.594-.822-.668a1 1 0 00-.618 0c-.228.074-.426.272-.822.668l-.738.737c-.396.397-.594.595-.668.823a1 1 0 000 .618c.074.228.272.426.668.822l4.738 4.737c.396.396.594.595.822.669a1 1 0 00.618 0c.228-.075.426-.273.822-.669l.738-.737c.396-.396.594-.594.668-.822a1 1 0 000-.618c-.074-.229-.272-.427-.668-.823zM11 11a2 2 0 110 4 2 2 0 010-4z"
    />
  </svg>
);
