import React from "react";
import { IconProps } from "../icon";

export const Award_03Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7.869 15.46L7 22l4.588-2.753c.15-.09.225-.135.305-.152a.5.5 0 01.214 0c.08.017.155.062.305.152L17 22l-.868-6.543m.294-11.208c.154.373.45.67.824.825l1.309.542a1.525 1.525 0 01.825 1.992l-.542 1.308a1.522 1.522 0 000 1.168l.542 1.307a1.525 1.525 0 01-.826 1.993l-1.308.542c-.373.154-.67.45-.825.824l-.542 1.309a1.524 1.524 0 01-1.992.825l-1.308-.542a1.525 1.525 0 00-1.166 0l-1.31.542a1.524 1.524 0 01-1.99-.824l-.542-1.31a1.524 1.524 0 00-.824-.825l-1.31-.542a1.524 1.524 0 01-.825-1.991l.542-1.308a1.525 1.525 0 000-1.167l-.542-1.31a1.525 1.525 0 01.826-1.992l1.307-.542c.374-.154.67-.45.825-.823l.543-1.309a1.524 1.524 0 011.991-.825l1.308.542c.374.154.793.154 1.167-.001l1.31-.54a1.525 1.525 0 011.99.825l.543 1.31v-.003z"
    />
  </svg>
);
