import React from "react";
import { IconProps } from "../icon";

export const CodeSnippet_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 17l5-5-5-5M7 7l-5 5 5 5m7-14l-4 18"
    />
  </svg>
);
