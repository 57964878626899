import React from "react";
import { IconProps } from "../icon";

export const ParagraphSpacingIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 10h-8m8-4h-8m8 8h-8m8 4h-8m-7 2V4m0 16l-3-3m3 3l3-3M6 4L3 7m3-3l3 3"
    />
  </svg>
);
