import React from "react";
import { IconProps } from "../icon";

export const SkewIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19.714 7l-1.428 10M6.143 7L4.357 17M18 5H8m8 14H6M5.6 7h.8c.56 0 .84 0 1.054-.109a1 1 0 00.437-.437C8 6.24 8 5.96 8 5.4v-.8c0-.56 0-.84-.109-1.054a1 1 0 00-.437-.437C7.24 3 6.96 3 6.4 3h-.8c-.56 0-.84 0-1.054.109a1 1 0 00-.437.437C4 3.76 4 4.04 4 4.6v.8c0 .56 0 .84.109 1.054a1 1 0 00.437.437C4.76 7 5.04 7 5.6 7zm-2 14h.8c.56 0 .84 0 1.054-.109a1 1 0 00.437-.437C6 20.24 6 19.96 6 19.4v-.8c0-.56 0-.84-.109-1.054a1 1 0 00-.437-.437C5.24 17 4.96 17 4.4 17h-.8c-.56 0-.84 0-1.054.109a1 1 0 00-.437.437C2 17.76 2 18.04 2 18.6v.8c0 .56 0 .84.109 1.054a1 1 0 00.437.437C2.76 21 3.04 21 3.6 21zm16-14h.8c.56 0 .84 0 1.054-.109a1 1 0 00.437-.437C22 6.24 22 5.96 22 5.4v-.8c0-.56 0-.84-.109-1.054a1 1 0 00-.437-.437C21.24 3 20.96 3 20.4 3h-.8c-.56 0-.84 0-1.054.109a1 1 0 00-.437.437C18 3.76 18 4.04 18 4.6v.8c0 .56 0 .84.109 1.054a1 1 0 00.437.437C18.76 7 19.04 7 19.6 7zm-2 14h.8c.56 0 .84 0 1.054-.109a1 1 0 00.437-.437C20 20.24 20 19.96 20 19.4v-.8c0-.56 0-.84-.109-1.054a1 1 0 00-.437-.437C19.24 17 18.96 17 18.4 17h-.8c-.56 0-.84 0-1.054.109a1 1 0 00-.437.437C16 17.76 16 18.04 16 18.6v.8c0 .56 0 .84.109 1.054a1 1 0 00.437.437C16.76 21 17.04 21 17.6 21z"
    />
  </svg>
);
