import React from "react";
import { IconProps } from "../icon";

export const Crop_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2 6h12.8c1.12 0 1.68 0 2.108.218a2 2 0 01.874.874C18 7.52 18 8.08 18 9.2V22m4-4H9.2c-1.12 0-1.68 0-2.108-.218a2 2 0 01-.874-.874C6 16.48 6 15.92 6 14.8V2"
    />
  </svg>
);
