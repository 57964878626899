import React from "react";
import { IconProps } from "../icon";

export const Award_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7 15.09V22l4.703-1.881c.11-.044.165-.066.221-.075a.5.5 0 01.152 0c.056.009.111.03.221.075L17 22v-6.91m2.5-5.59a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0z"
    />
  </svg>
);
